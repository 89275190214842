import React, { useMemo } from 'react'
import { I18n } from '@front/volcanion'

import { Box, Link, Typography, TableCell } from '@mui/material'
import { TextCell } from '@abra/elements/table/cells/basics/'

import OrderUtils from '@front/volcanion/utils/order'
import FormatUtils from '@front/squirtle/utils/format'
import GeoUtils from '@front/volcanion/utils/geo'

const OrderClientIDCell = ({ color, row, ...props }) =>
  <Typography
    sx={{
      color,
      fontWeight: 800,
      fontSize: 'subtitle1.fontSize',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}
  >
    <Link sx={{ color: 'inherit' }}
      target='_blank'
      onClick={(e) => {
        window.open(`/order/${_.get(row, 'order.order_id')}`, '_blank')
        e.stopPropagation()
      }}
    >
      {_.get(row, 'order.order_client_id') || '-'}
    </Link>
  </Typography>

const StepPackageCell = ({ row, ...props }) => {
  const load_type = _.get(row, 'order.commercial_package.load_type')
  const type = load_type === 'user' ? 'passenger' : 'package'
  const reason = _.get(row, 'order.reason')
  const expected = _.get(row, `order.${type}_count`, '-')
  const actual = _.get(row, `order.assigned_transport.${type}_count`, '-')

  const color = useMemo(() => {
    if (_.includes(['not_loaded'], reason)) return 'red'
    else if (actual < expected) return 'orange'
    else return 'text.input'
  }, [reason, expected, actual])


  return <TextCell value={`${actual} /  ${expected}`} sx={{ color }} />
}

const Row = ({ row, isSelected, ...props }) => {
  const type = OrderUtils.getOrderType(_.get(row, 'order.do.auth.application.name'), _.get(row, 'order.commercial_package.load_type'))
  const src_contact = _.get(_.head(_.get(row, 'order.loads')), 'src_contact')

  return <>
    <TableCell size={'small'}><TextCell value={_.isNumber(_.get(row, 'index')) ? _.toNumber(_.get(row, 'index')) + 1 : '-'} /></TableCell>
    <TableCell size={'small'}><TextCell value={FormatUtils.formatDateBackToFront(_.get(row, 'estimated_arrival_time'))} /></TableCell>
    <TableCell size={'small'}><TextCell value={I18n.t(`step.type.${_.get(row, 'type')}`)} /></TableCell>
    <TableCell size={'small'}><OrderClientIDCell color={OrderUtils.getColorTheme(type)} row={row} /></TableCell>
    <TableCell size={'small'}><StepPackageCell row={row} /></TableCell>
    <TableCell size={'small'}><TextCell value={src_contact} /></TableCell>
    <TableCell size={'small'}>
      {_.get(row, 'type') === 'pickup'
        ? <Box sx={{ display: 'flex', p: '3px', alignItems: 'center' }}>
          <Box sx={{ width: 9, height: 9, borderRadius: '50%', boxShadow: 3, bgcolor: 'green', opacity: 0.9, mr: 1 }} />
          <TextCell value={GeoUtils.format(_.get(row, 'order.source'))} />
        </Box>
        : <Box sx={{ display: 'flex', p: '3px', alignItems: 'center' }}>
          <Box sx={{ width: 9, height: 9, borderRadius: '50%', boxShadow: 3, bgcolor: 'red', opacity: 0.9, mr: 1 }} />
          <TextCell value={GeoUtils.format(_.get(row, 'order.destination'))} />
        </Box>
      }
    </TableCell>
  </>
}

export default React.memo(Row)
