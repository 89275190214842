import Aux from './Aux'
class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      return {
        ...filter
      }
    }
  }
  static formToFilterHandler() {
    return function formToFilter(values, extra, meta, state) {
      const { requestedAt_start, requestedAt_end, ...rest } = values
      const requestedAt = Aux.formatRequestedAt(requestedAt_start, requestedAt_end)

      if (!!requestedAt_start || !!requestedAt_end)
        _.merge(rest, { orders: { requestedAt } })

      if (_.has(rest, 'orders.source.name'))
        _.merge(rest, { orders: { source: { name: { contains: rest?.orders?.source?.name } } } })

      if (!!rest?.orders?.commercial_formula)
        _.merge(rest, {
          orders: {
            or: [
              { do: { commercial_formula: rest?.orders?.commercial_formula } },
              { service: { contract: { formula: rest?.orders?.commercial_formula } } },
            ]
          }
        })
      return _.omit(rest, 'orders.commercial_formula')
    }
  }
}

export default Callbacks
